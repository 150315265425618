.job-page__type-of-apply{
    color: #575757;
    text-align: center;

    p:nth-of-type(1){
        font-size: 18px;
        font-weight: 500;
    };
    
    p:nth-of-type(2){
        font-size: 14px;
        margin: 0;
    }
    
    p:nth-of-type(3){
        font-size: 23px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 40px;
    }
.type-of-apply__button-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .type-of-apply__button{
        width: 80%;
        margin: 10px 0;
        
        >button{
            font-size: 14px!important;
            font-weight: 500!important;
            border-radius: 11px!important;
            box-shadow: none!important;
            height: 37px;
        }
    }
}

.quick-apply{
    .quick-apply__title{
        font-weight: 600;
        font-size: 23px;
        color: #575757;
        margin-bottom: 60px;
    }

    .quick-apply__statement{
        font-size: 12px;
        font-weight: 400;
        color: #575757;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .quick-apply__button{
        > button{
            font-size: 14px!important;
            font-weight: 500!important;
            border-radius: 11px!important;
            box-shadow: none!important;
            height: 37px;
        }
    }
}