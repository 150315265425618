@import 'config';

.imprint{
    margin-top: 170px;
    h1{
        text-align: center;
        color:$primary-color;
        margin: 100px 0 8% 0;
        
    }
    h2{
        color:$primary-color;
        margin-bottom: 10px;
    }
    .red{
        color: $primary-color;
    }
    .textblock{
       margin: 3% 0;
      span{
        display: inline-block;
      }
      p{
          margin: 3% 0;
      }
    }
    .text-with-title{
        margin: 2% 0;
       line-height: 30px;

    }
}