@media (max-width: 767px) {
    .specific {
        top: 0px !important;
    }
}

.quick-apply__badge{
    font-size: 13px;
    font-weight: 500;
    color: #929292;
    background-color: #F0F0F0;
    border-radius: 23px;
    padding: 3px 20px;
    margin: 0;
}