.select__control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  min-height: 1.1876em !important;
  border-radius: 0;
  border-color: #ddd !important;
}
.select__control:hover {
  cursor: pointer;
  border-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e83741 !important;
}
.select__control--is-focused,
.select__control--menu-is-open {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e83741 !important;
}
.select__indicator-separator {
  display: none !important;
}

.select__menu {
  z-index: 1000 !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  background-color: transparent !important;
}
