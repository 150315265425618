.partner-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    .companies-map{
        margin-top: 70px;
        position: relative;
        width: 78%;
        height: 550px;
  border-radius: 25px;
  border: solid 1px #707070;
  box-shadow: 0px 23px 20px 0 rgba(31, 29, 13, 0.2);
  .map-title{
   // width: 720px;
    height: 120px;
    border-radius: 25px;
    box-shadow: 7px 7px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    position: absolute;
    top:-45px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .first-p-p{
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 15px;
    }
    .second-p-p{
        font-size: 35px;
        font-weight: bold;
        color: #e71e2c;
        text-transform: uppercase;
        word-wrap: break-word;
    }
  }
  
  >.CompanyProfile_mapContainer__3wuKe{
    height: 100%;
    width: 100%;
    >div{
      border-radius: 25px;
    height: 100%!important;
  }

  }


    }

    .partner-job-preview{
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: bold;
        color: #e61f2a;
      margin-top: 50px;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
      svg{
        width: 25px;
      }
    }

    .partner-details-list{
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
    }

    @media (max-width: 798px) {
     .second-p-p {
      font-size: 20px !important;
     }
    }
}

