@import '../../assets/scss/config.scss';

.search-bar{
    width: fit-content;
    position: relative;
    margin: 0 auto;
    min-width: 460px;

    

    @media (max-width: 992px) {
        width: fit-content;
    }
    .search-fields{
        background-color: #e6e6e6;
        border-radius: 27px;
        box-shadow: 10px 13px 26px 0 rgba(0, 0, 0, 0.16);
        padding: 12px;
        .search-bar-input{
            height: 42px;
            width: 430px;
            border-radius: 13px;
            background-color: #fff!important;
            padding-left: 10px;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: none!important;
          }
          .MuiSelect-select{
            padding: 10px 14px;
          }

          input{
            padding: 10.5px 14px;
        }
        fieldset{
            border: none;
        }

        .gewerbepark, .firmenname{
            font-family: Montserrat;
            font-size: 15px;
            color: #7c7c7c;
            padding-left: 40px;
            background: url(../../assets/images/icon-search.svg) no-repeat;
            background-size: 20px;
            background-position: 15px center;
        }
        .firma{
            background: url(../../assets/images/icon-business-search.svg) no-repeat;
            background-position: 15px center;
            background-size: 20px;
            margin-right: 80px;
            //margin-left: 30px;
        }
        .anstellungsart{
            background: url(../../assets/images/icon-clock.svg) no-repeat;
            background-position: 15px center;
            background-size: 20px;
        }

        @media (max-width: 1024px) {
            .anstellungsart {
                width: 430px;
            }
        }

     
        .branch{
            background: url(../../assets/images/icon-business-center-outline.svg) no-repeat;
            background-position: 15px center;
            margin-left: 30px;
            margin-right: 80px;
            background-size: 20px;
        }
        .voluntering{
            background: url(../../assets/images/icon-business-center-outline.svg) no-repeat;
            background-position: 15px center;
            background-size: 20px;
            width: 885px;

        }

       
        .search-fields-row{
            display: flex;
        }  
        .search-bar-googlemap{
            width: 430px;
            margin-right: 140px;
            background: url(../../assets/images/icon-location.svg) no-repeat;
            padding-left: 60px !important;
            background-size: 18px;
            background-position: 18px center;
            font-size: 15px;
        }
        @media screen and (max-width: 767px)  {
            .search-bar-googlemap {
                width: 250px;
            }
        }
        @media screen and (max-width: 1024px)  {
            .voluntering{
                
                width: 430px;
    
            }
        }
    }

 
    button{
        border: none;
        outline: none;
        background-color: $primary-color;
        text-transform: uppercase;
        border-radius: 50%;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        width: 125px;
        height: 125px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -60px;
        top: -45%;
        cursor: pointer;
        box-shadow: 10px 13px 16px 0 rgba(0, 0, 0, 0.16);
        svg{
            width: 34px;
            height: 34px;
            margin-bottom: 10px;
        }
    }
    
    
    input::placeholder, input::-webkit-input-placeholder, input:-ms-input-placeholder{
        font-family: Montserrat;
        font-size: 15px;
        padding-left: 0;
        opacity: 1;
        line-height: 0;
        color: #7c7c7c;
    }

    
}

//mobile responsive 


@media (max-width: 767px) {
    .search-bar {
        left: 0 !important;
        right: 0 !important;
        margin: 0 auto !important;
        min-width: 220px;
        margin-bottom: 50px !important;
        .search-fields {
            padding-bottom: 50px;
         
            .search-fields-row {
                max-width: 250px;
                margin-left: auto;
                margin-right: auto;
            }

            .search-bar-input {
                width: 100%;
               margin-right: 0px !important;
            }
        }
    }

    button {
        top: 85% ;
        //left: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-bottom: 30px;
      
    } 
    .ansehen-button {
        margin-bottom: 0px;
    }

    .searchB {
        top: 85% !important;
    }
}

@media (max-width: 350px) {
    .search-bar {
        margin-top: 20px !important;
        max-width: 220px;
    }
}