.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.container {
  position: relative;
  background-color: #fff;
  padding: 12px;
  border-radius: 15px;
  outline: none;
}

.image {
  max-height: 90vh;
  max-width: 90vw;
  object-fit: contain;
}

.closeBtn {
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
}
