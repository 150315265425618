@import "./config.scss";
.partner-wrapper{
    .main-container{
       > div{
            margin-bottom: 0;
        }
    }
}
.partner{
    .partner-header{
        background-image: url(../images/partner-background.png);
        background-position: center;
        background-size:cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100vh;
        position: relative;
        .header-content{
            
            position: absolute;
            top: 35%;
            left: 45%;
            z-index: 1;
            >h2{
                font-size: 40px;
                font-weight: bold;
                color: $primary-color;
                line-height: 1.37;
                margin-bottom: 30px;

              
            }
            >p{
                font-size: 17px;
                color: #000;
                line-height: 1.80;
                margin-bottom: 45px;
            }
            .header-buttons{
                button:nth-of-type(1){
                        margin-right: 60px;
                }
            }
        }

        
    }
    .header-wrapper{
        position: relative;
        width: 100%;
        //height: 100vh;
        margin-bottom: 150px;
        overflow: hidden;

        > .shadow{
            position: absolute;
            width: 125%;
            height: 100%;
            box-shadow: inset 0px -79px 118px 0 #fff !important;
            top: 0;
            left: -41px;
        }
    }

.partner-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   
   
    .first-partner{
        font-size: 17px;
        text-align: center;
        margin-bottom: 0;
        line-height: 1.13;
    }
    .second-p{
        font-size: 40px;
        font-weight: bold;
        color: $primary-color;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1.39;
    }
}
.partners-list{
    margin: 60px auto;
    padding: 20px 160px;
    display: grid;
    gap: 80px 50px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    width: 100%;
  
    @media (max-width: 650px) {
      grid-template-columns: none;
    }
}
}

@media (max-width: 840px) {
    .partner {
        .partner-header {
         
           // background-image: none;
           
            .header-content {
                top: 40%;
               left: 38%;
               >h2 {
                font-size: 30px;
               }
               >p {
                font-size: 13px;
               }
                .header-buttons{
         
                    button:nth-of-type(1){
                            margin-right: 20px;
                            margin-bottom: 10px;
                    }
                }
               
            }
        }

        .header-wrapper {
            margin-bottom: 100px;
        }
    }
  }

  @media (max-width: 767px) {
    .partner {
        overflow: hidden;
        .partner-content {
            .second-p {
                font-size: 30px;
            }
        }
        .partner-header {
         
           // background-image: none;
           
            .header-content {
                top: 30%;
               left: 28%;
               >h2 {
                font-size: 30px;
               }
               >p {
                font-size: 13px;
               }
                .header-buttons{
         
                    button:nth-of-type(1){
                            margin-right: 20px;
                            margin-bottom: 10px !important;
                    }
                }
               
            }
        }

        .header-wrapper {
            margin-bottom: 100px;
        }
    }
  }