.ad-component {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -280px;
  width: 100%;
  z-index: 0;
}

.close-ad {
  display: flex;
  margin-bottom: 5px;
  justify-content: end;
  font-size: 14px;
  cursor: pointer;
}

.ad-content {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: center;

  gap: 20px;
}

.video-wrapper,
.image-wrapper {
  position: relative;
  cursor: pointer;
  outline: 6px solid black;
  box-sizing: border-box;
  max-height: 200px;
  width: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-video {
  width: 100%;
  height: 100%;
}

.ad-label {
  position: absolute;
  top: 0px;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 2px;
  font-size: 12px;
  z-index: 2;
}
/* .ad-label-video {
  position: absolute;
  top: 30px;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 2px;
  font-size: 12px;
  z-index: 2;
} */

.image-wrapper img {
  max-height: 200px;
  /* display: block; */
  width: 100%;
}

.ad-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.toggle-button,
.close-button {
  padding: 5px 10px;
}

/* Responsive styling */
@media (max-width: 650px) {
  .ad-component {
    bottom: -180px;
  }

  .show-image .video-wrapper {
    display: none;
  }
}
