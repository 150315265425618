.blogHeader{
    display: flex;
    justify-content: space-between;
    .logoAndTitle {
        display: flex;
        .blog-logo{
            min-width: 100px;
            max-width: 100px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: solid 1.5px #e71e2c;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 50%;
                background-color: white;
                object-position: center;
            }
        }
        .blogTitle {
            margin: auto 10px auto 10px;
            font-weight: 600;
            font-size: 26px;
        }
    }
    .socialButtons{
        display: flex;
        flex-direction: column;
        margin: auto 0;
    }
   

}

.blogText {
    margin-top: 15%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
}

.blogPhoto {
    margin-top: 10%;
    height: auto;
    width: 100%;
}

.wrapDiv{
    margin: 10% 20% 0px;
}

@media (max-width: 600px) {
    .wrapDiv {
        margin: 10% 5% 0px;
    }

    .blogHeader {
        flex-direction: column;
        .socialButtons {
            margin-top: 7%;
        }

    }

    .blogText {
        margin-top: 7%;
    }
}