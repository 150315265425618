.create-profile {
  display: flex;
  align-items: flex-end;
  //height: 100vh;
  width: 100%;
  .create-profile-container {
    width: 100%;
    height: 95%;
    min-width: 300px;

   
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .logo {
      width: 260px;
      height: auto;
      display: block;
      margin-top: 40px;
    }

    .create-profile-button--back {
      border: none;
      outline: none;
      background-color: transparent;
      color: #db1c2b;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      top: 60px;
      left: 50px;
      svg {
        width: 18px;
        margin-right: 10px;
      }
    }
  }

  .create-profile-next {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    button {
      width: 240px !important;
      border-radius: 5px !important;
      font-size: 14px !important;
      font-weight: bold !important;
      margin-left: 0;
      box-shadow: none !important;
      background-color: #000 !important;
    }
    .MuiButton-containedPrimary:hover {
      border: none !important;
      color: #fff !important;
      background-color: #000000bd !important;
    }
    .MuiButton-contained.Mui-disabled {
      background-color: rgb(218, 218, 218) !important;
    }
  }

  .create-profile-back {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    button {
      width: 240px !important;
      border-radius: 5px !important;
      font-size: 14px !important;
      font-weight: bold !important;
      margin-left: 0;
      box-shadow: none !important;
      background-color: #fff !important;
      color: #494949;
      border: 2px solid #494949 !important;
    }
    .MuiButton-containedPrimary:hover {
      border: none !important;
      color: #fff !important;
      background-color: #000000bd !important;
    }
    .MuiButton-contained.Mui-disabled {
      background-color: rgb(218, 218, 218) !important;
    }
  }
}

.create-profile-select {
  background-color: #fff !important;
  color: #7c7c7c;
  font-size: 13px !important;
  border-radius: 5px;
  padding-left: 20px !important;
  border: solid 1px #d6d6d6 !important;
  height: 40px !important;
  width: 100% !important;
  fieldset,
  .select__control {
    border: none !important;
  }
  white-space: normal;
  .MuiSelect-select {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    white-space: normal !important;
  }
  .MuiTextField-root {
    height: 100%;
  }
  .MuiOutlinedInput-root {
    padding: 0;
    font-size: 13px;
    height: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-right: 150px;
    width: 100%;

    .MuiAutocomplete-endAdornment {
      background: #fff;
      right: 0;
      padding-right: 9px;
    }

    .MuiAutocomplete-tag {
      background-color: transparent;
    }
  }
}

.vocational-wrapper {
  width: 100%;
  .vocational {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 5px 0;
    white-space: nowrap;

    .vocational-selected {
      display: flex;
      align-items: center;
      width: 50%;
      flex-wrap: wrap;
      p {
        font-size: 14px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        svg {
          width: 16px;
          height: 16px;
          cursor: pointer;
          margin: 0 5px;
        }
      }
    }
  }
  .create-profile-select {
    width: 42%;
    margin-right: 30px;
    .select__control {
      border: none !important;
      height: 100%;
    
    }
  }

  button {
    box-shadow: none !important;
    font-size: 14px !important;
  }
}

.vocational-left {
  display: flex;
  align-items: center;
  .vocational-button--plus {
    background: transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px #01a727;
    color: #01a727;
    font-weight: bold;
    cursor: pointer;
    margin-left: 20px;
  }

  .vocational-button--minus {
    border: solid 2px #db1c2b;
    color: #db1c2b;
    margin-bottom: 0px;
  }
}

.create-profile-step-four {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > div {
    width: 50%;
  }

  > div:nth-of-type(1) {
    margin-right: 20px;
  }

  .create-profile-hobies {
    button {
      box-shadow: none !important;
      font-size: 14px !important;
    }
  }
}

.formTwo {
  display: flex;
  justify-content: center;
 
  overflow-y: visible;
}

.highSchool {
  display: flex;
  margin: 5px 0;
}

.doneImg {
  width: 325px;
  height: 208px;
  margin: 0 auto;
}

.doneParagraph {
  text-align: center;
  margin-top: 15px;
  color: #000;
  width: 45%;
}

@media (max-width: 992px) {
  .create-profile {
    height: auto;
    .create-profile-container {
      overflow-y: auto;
      background-image: none;

      .logo {
        margin-top: 20px;
        height: 100px;
      }

      .create-profile-button--back {
        position: relative;
        top: 30px;
        left: 0px;
      }
    }
    .create-profile-back {
      width: 100px;
    }
    .create-profile-next {
      width: 100px;
    }

    button {
      top: 0px;
    }
  }

  .create-profile-select {
    padding-left: 5px !important;
  }

  
  .vocational-wrapper {
    .vocational {
      flex-direction: column;
      align-items: normal;
      white-space: initial;
    }

    .vocational-selected {
      width: 100% !important;
    }
  }

  .divHif {
    flex-direction: column;
  }

  .buttonHif {
    margin-top: 20px;
  }

  .highSchool {
    flex-direction: column;
  }

  .create-profile-step-four {
    flex-direction: column;
    > div {
      width: 100%;
    }

    > div:nth-of-type(1) {
      margin-right: 0px;
    }
  }

  .doneImg {
    width: 70%;
    height: auto;
    margin-top: 60px;
  }

  .doneParagraph {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px !important;
    color: #000;
    width: 85%;
  }
}

@media (min-width: 1024px) {
  .stepper {
    margin-top: 200px;
  }
}

@media (min-width: 1100px) {
  .stepper {
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .create-profile {
    .create-profile-container {
      .logo {
        width: 150px;
        height: 50px;
      }
    }
  }
}
