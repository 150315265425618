.wrapper {
  padding: 0;
  border: 1px solid #ddd;
}

.editor {
  min-height: 200px;
  max-height: 350px;
  overflow-y: auto;
  padding: 15px 10px;

  & div {
    margin: 0px;
  }

  //toolbar
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background: #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 8px;
  }
}

.toolbar {
  margin: 0;
  padding: 3px;
}

.set {
  margin: 0 5px;

  & div {
    margin: 0;
    padding: 15px 10px;
  }

  & > div:hover {
    background-color: #f4f4f4;
    box-shadow: none;
  }
}

.dropdown {
  margin: 0;
  padding: 15px 10px;
  width: 140px;

  &:hover {
    background-color: #f4f4f4;
    box-shadow: none;
  }
  a {
    text-decoration: none;
    color: rgb(19, 19, 19);
  }
}

.dropdownList {
  width: 140px;
  top: 16px;
  left: -11px;
  overflow: hidden;
}
