.modal-select__result{
    max-height: 13rem;
    overflow-y: auto;
    width: 100%;
    margin-top: 1rem;
    padding-right: 5px;
}
.modal-select__result::-webkit-scrollbar {
    width: 4px;
  }
.modal-select__result::-webkit-scrollbar-track {
    background: #fafafa;
  }
  
  .modal-select__result::-webkit-scrollbar-thumb {
    background: #c9333c;
    border-radius: 8px;
  }