@import '../../assets/scss/deviceSize';
// @import '../../assets/scss/config';

.container {
  
 
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 10px;

  a {
    color: #e30613;
    cursor: pointer;
  }

  &:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
  }
}

.loginParent {
  background-image: url(../../assets/images/loginBackground.png);
  background-position: center;

  background-size: cover !important;
  position: relative;

  width: 100%;
  height: 100vh;
  overflow-y: hidden;

}



.loginButton {
  button {
    font-weight: bold !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    left: 0px;
  }
  
}


.input {
  text-align: center;
  width: 100%;
  height: 44px;
  outline: none;
  border: solid 1px #d6d6d6;
  border-radius: 10px;
  margin: 0 auto 10px auto;
  font-size: 15px;
  &:focus {
    border-color: #e30613;
  }
}
::placeholder {
  color: #a2a2a2;
}

input:focus::placeholder {
  color: transparent;
}


.label {
  color: #929292;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  font-weight: normal;
}

.input[type='email'] .label:focus {
  color: #5264ae;
}

.buttonsContainer {
  width: 100%;
  max-width: 100%;
  margin: 10px auto;
  text-align: center;
}

@media only screen and (max-width: $newMedium) {
  .container {
    padding: 25px;
  }

  .buttonsContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
 margin-left: 10px;
 margin-right: 10px;
    
    box-shadow: none;
  }

  .loginParent {
    background-image: none;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100vh;
  }
}

@media (min-width: 1500px) {
  .container {
    margin-top: 250px !important;
  }

  
}

@media (max-width: 767px) {
  .container {
    margin-left: 15%;
    margin-right: 15%;
  }

  .loginParent {
    overflow: hidden;
    overflow-y: scroll;
  }
}
