@import '../../assets/scss/deviceSize';

// JobDetailsItem.js
.itemImage {
  margin-right: 8px;
  width: 30px;
}

//JobPreview.js
.jobPreview{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .imageBlock {
    height: 520px;
    width: 100%;
      img{
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
  }

  .backgroundMargin{
    height: 535px;
  }
  .companyInfo{
    background-image: url(../../assets/images/bewerber-fifth-background.png);
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 690px;
    position: absolute;
    top: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .companyImage{
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      top: 65px;
      background-color: #fff;
      width: 150px;
      height: 150px;
      border: solid 2px #e71e2c;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
    }

    .jobTitle{
      font-size: 26px;
      font-weight: bold;
      color: #1f1d0d;
      margin-bottom: 0;
      margin-top: 230px;
      position: relative;
    }
    .jobSubtitle{
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .introduction{
    text-align: center;
    color: #707070;
    font-size: 16px;
    margin-top: 100px;
    width: 75%;
  }
  .jobRequirements{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 40px;
    .requirementCard{
      width: 400px;
      height: 610px;
      border-radius: 15px;
      background-color: #ededed;
      text-align: center;
      padding: 25px 20px;
      margin: 10px 20px;
      overflow-y: auto;

      h4{
        font-size: 20px;
        color: #e61f2a;
        margin-bottom: 15px;
        font-weight: bold;
      }
      > div{
        color: #707070;
        font-size: 15px;
        height: fit-content;

        ul{
          margin: 0;
          padding: 0;
          li{
            list-style: none;
            padding: 10px 0;
          }
        }
      }
    }

  }

  .searchBar{
      .searchFields{
        position: relative;
        padding-bottom: 30px;
        border-radius: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
        .input{
          color: #7c7c7c;
          font-size: 14px;
          height: 38px;
          border-radius: 10px;
          margin-bottom: 15px;
          background: url(../../assets/images/icon-search.svg) no-repeat;
          background-size: 18px;
          background-position: 18px center;
          padding-left: 35px;
        }
        .selectedLocation{
          display: flex;
          align-items: center;
          margin: 8px 0;
          p{
            margin: 0;
            font-size: 14px;
            margin-right: 10px;
          color: #7c7c7c;
          font-weight: bold;
          }
          .input{
            margin: 0;
            background: url(../../assets/images/icon-clock.svg) no-repeat;
            background-size: 18px;
            background-position: 18px center;
            padding-left: 35px;
          }
        }
        .submitBewerben{
          position: absolute;
          width: 50%;
          bottom: 18px;
          right: 170px;
          button{
            width: 100%;
            height: fit-content;
            font-size: 14px!important;
            font-weight: bold!important;
            color: #fff;
            padding: 2px;
            border-radius: 10px!important;
          }
        }
      }
  }
}
.companyContent{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;

  .contentCard{
    height: 300px;
    width: 300px;
    margin: 15px 20px;
    padding: 20px;
    padding-top: 60px;
    border-radius: 12.5px;
    box-shadow: 0 7.5px 17.5px 0 rgba(0, 0, 0, 0.39);
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    >button{
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      padding: 7px 10px 7px;
      border-radius: 2px;
      box-shadow: 5px 5px 5.5px 0 rgba(0, 0, 0, 0.16);
      background-color: #e61f2a;
      border: none;
      outline: none;
      color: #fff;
      width: fit-content;
      min-width: 50%;
      font-weight: bold;
      font-size: 14px;
      bottom: -17px;
      text-transform: uppercase;
    }

    .cardIcon{
      width: 85px;
      height: 85px;
      border: solid 1px #e61f2a;
      background-color: #fff;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      top: -40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 50%;
        height: auto;
        object-fit: contain;
      } 
    }

    > img{
      width: auto;
      height: 47px;
      max-width: 100%;
    }
    .companyName{
      margin-top: 16px;
      font-weight: bold;
      font-size: 16px;
      color: #707070;
      text-align: center;
      margin-bottom: 0;
    }
    .companyLocation{
      font-size: 14px;
      color: #707070;
      text-align: center;
    }
    .cardList{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 15px;
      width: 100%;
      svg{
        width: 25px;
        height: auto;
        margin-right: 12px;
      }
        .listTitle{
          font-weight: bold;
        }
        .listTitle, .listName{
          font-size: 14px;
          color: #707070;
          margin: 0;

      }
    }
    .contact{
      color: #e61f2a;
      font-weight: bold;
    }
    .contactName{
      font-weight: bold;
      color: #707070;
      margin-bottom: 5px;
    }
    .contactTitle{
        font-size: 14px;
        margin-bottom: 30px;
        text-align: center;
    }
    .contactPhone{
      font-size: 14px;
      margin: 2px;
      text-align: center;
    }
  }
}

.jobHeading {
  font: 600 34px 'Montserrat';
  color: #3a3a3a;
  margin: 0px 10px 30px;
  hyphens: auto;
}

//SocialNetworks.js
.socialIcon {
  width: 29px;
  height: 29px;
  margin: 5px;
}

.socialContainer p {
  margin: 5px 10px 5px 0;
}

.jobSpecification{
  font: 500 26px 'Montserrat';
}

.ikonica {
  margin-bottom: 0px !important;

}

// media queries
@media (max-width: 1028px) {
  .jobPreview {
    .companyInfo {
      background-image: none;
      height: auto;
      position: relative;
      top: 0px;
    }
    .backgroundMargin{
      height: 0px;
    }
  }

  .companyContent {
    .contentCard {
      margin-bottom: 60px;
    }
  }
  
}
@media (max-width: $newExtraSmall) {
  .infoContainer {
    flex-direction: column;
  }

  .companyInfo {
    max-width: none;
    text-align: center;
  }

  .jobInfo {
    border: none;
    max-width: none;
    border-top: 1px solid #e83741;
    margin: 25px 0;
    padding: 0;
  }

  .jobHeading {
    font-size: 28px;
  }

  .jobParagraphContainer {
    padding: 5px 0;
  }

  .socialContainer {
    justify-content: center;
  }
}


@media (max-width: 767px) {
  .jobPreview {
    .searchBar {
      .searchFields {
        .submitBewerben {
          right: 0;
          left: 0;
          margin: 0 auto;
          width: 80%;
          button {
            font-size: 13px !important;
          }
        }


      }
    }
  }

  .companyInfo {
    text-align: left;
  }

}



@media (min-width: 1600px) {
  .jobPreview {
    .imageBlock {
      max-width: 1600px;
    }
  }

  .companyInfo {
    max-width: 1600px;
  }
  
}
