.company-profile {
  width: 100%;
  .profile-image {
    width: 100%;
    height: 539.5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .background-margin {
    height: 125px;
  }
  // .push-top {
  //   top: -400px;
  //   position: relative;
  // }
  // .subpage-div {
  //   top: -400px;
  //   position: relative;
  // }
  .company-content {
    background-image: url(../../assets/images/bewerber-fifth-background.png);
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 690px;

    position: relative;
    margin-top: -250px;

    .company-image {
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      top: 55px;
      background-color: #fff;
      width: 170px;
      height: 170px;
      border: solid 2px #e71e2c;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
    }
  }
  .company-social-links {
    position: relative;
    width: 100%;
    margin-top: 80px;

    > div {
      left: 0;
      right: 0;
      .social-buttons {
        flex-direction: row;
        button:nth-of-type(1) {
          margin-right: 5px;
        }
      }
    }
  }
}

.subpage-div {
  margin-top: -340px;
}

@media (max-width: 1128px) {
  .company-profile {
    .company-content {
      background-image: none;
      height: 500px;
      margin-top: 15px;
      .company-image {
        //position: absolute;
      }
    }
    
  }

  .subpage-div {
    margin-top: -200px;
  }
}

@media (max-width: 720px) {
  .company-profile {
    .company-content {
      background-image: none;
      .company-image {
       // position: absolute;
      }
    }
  }
  
}
