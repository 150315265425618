@import '../../../assets/scss/deviceSize';

.modalScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modalScreen:focus,
.modalWrapper:focus,
.modalWrapper,
.modalScreen {
  outline: none !important;
}

.modalWrapper {
  width: 95%;
  max-width: 700px;
  height: 85%;
  max-height: 70vh;
  background-color: #fff;
  border-radius: 30px;
  position: relative;
  padding: 30px 0 30px 50px;
}

.smallModal {
  height: 500px;
}

.modalContent {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: auto;
  padding: 10px 20px;

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }
  &::-webkit-scrollbar-track {
    background: #fafafa;
  }
  &::-webkit-scrollbar-thumb {
    background: #c9333c;
    border-radius: 8px;
  }
}

.modalContent h2:first-child {
  color: #e83741;
  padding-bottom: 15px;
  font-size: 26px;
}

.borderLeft {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #e83741;
  border-radius: 30px 0 0 30px;
  width: 50px;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  cursor: pointer;
  z-index: 100;
}

@media (max-width: $newExtraSmall) {
  .borderLeft {
    width: 25px;
  }

  .modalWrapper {
    padding-left: 20px;
  }
}
