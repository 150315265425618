.label{
    font-size: 15px;
    font-weight: 400;
    color: #929292;
    margin-bottom: 10px;
}

.input {
  border: none;
  border-bottom: 1px solid #CFCFCF;
  outline: none;
  margin-bottom: 20px;
  width: 100%;
}