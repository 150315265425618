.bewerber-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    
    .section-card{
        width: 80%;
        height: 600px;
        border-radius: 25px;
        background-color: #e6e6e6;      
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
        background-image: url(./../../assets/images/step-background.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-between;
        position: relative;

       

      

        .section-text{
            margin-top: 30px;
            margin-left: 90px;
            max-width: 55%;
        }

      

        .first-p-B{
            font-family: Montserrat;
            font-size: 17px;
            color: #000;
            span:first-of-type{
                font-weight: bold;
            }
            span:nth-of-type(2){
                color: #e71d31;
            }
        }
        h3{
            font-size: 40px;
            font-weight: bold;
            color: #e71e2c;
        }

        ul{
            margin-top: 50px;
            padding-left: 0;
            li{
                font-size: 16px;
                line-height: 2.2;
                color: #000;
                list-style: none;
                margin-bottom: 5px;

                svg{
                    margin-right: 10px;
                    width: 20px;
                    height: auto;
                }
            }
        }

        >img{
            position: absolute;
            right:0;
            height: 100%;
        border-radius: 25px;
        }
    }

    @media (max-width: 1024px) {
        .section-card {
            .section-text {
                margin-left: 40px;
            }
        }
    }
    

    .search-bar{
        bottom: -40px;
        right: 40px;
        left: 0;
        margin: auto;
        //width: 80%;
        position: absolute;

        button{
            border: solid 5px #000;
            @media (max-width: 1024px) {
                right: -40px !important;
               // left: 0;
               // margin: 0 auto;
            }

            @media (max-width: 767px) {
                right: 0px !important;
                left: 0;
                margin: 0 auto;
            }
        }

        .search-fields {
            background-color: #000;
            .search-bar-googlemap{
                margin-right: 80px;
            }

            .search-fields-row{
                div:has(.search-bar-googlemap){
                        display: inline-flex;
                        min-width: 0;
                }
            }
        }
   
    }

    @media (max-width: 1024px) {
        .section-card {
            height: 800px;
        }

        .search-bar {
            bottom: 30px;
           
        }
    }

    .first-section-mirror{
        width: 100%;
        margin-top: 30px;
        position: absolute;
        bottom: -150px;
    }
}


//mobile responsive 320px - 768px


@media (max-width: 767px) {
    .bewerber-section  {
        .section-card {
            height: 650px;
            .section-text {
                margin-left: 20px;
                max-width: 100%;
            }
            .first-p-B {
                font-size: 12px;
            }

            h3 {
                font-size: 20px;
            }

            ul {
                margin-top: 15px;
            }

            ul li {
                font-size: 10px;
            }
        }
    }
}
/*
second section
*/
.bewerber-section-second{
    margin-top: 150px;
    .section-card{
        background-image: url(./../../assets/images/step-2-background.png);
        justify-content: flex-start;

        .section-phone{
            width: 40%;
            position: relative;
            img{
                position: absolute;
                left: -300px;
                top: -108px;
                height: 870px;
            }
        }

        @media (max-width: 1024px) {
            .section-phone {
                width: 25%; 
                img {
                    left: -200px;
                    height: 550px;
                    top: -70px;
                }
            }
        }

        .section-text{
            margin-top: 120px;
            h3{
                margin-bottom: 20px;
            }
            .second-p{
                font-family: Montserrat;
            font-size: 17px;
            color: #000;
                margin-top: 40px;
            }
            .third-p{
                text-transform: uppercase;
                font-weight: bold;
                color: #e71e2c;
                font-size: 23px;
                margin-top: 120px;
                margin-left: 25px;
            }
        }

        .third-p{
            text-transform: uppercase;
            font-weight: bold;
            color: #e71e2c;
            font-size: 23px;
            margin-top: 120px;
            margin-left: 75px;
            margin-bottom: 40px;
        }

        @media (max-width: 1024px) {
            .section-text {
                margin-left: 150px;
                margin-top: 270px;
                .third-p {
                    margin-left: -25%;
                }
            }
        }

        .text-buttons {
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: -20px;
            right: 140px;
        }

        .download-buttons{
          display: flex;
            > div{
                width: 160px;
                margin: 0 25px;
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        @media (max-width: 1024px) {
            .download-buttons {
                right: 50px;
            }
        }
    }
}


//mobile responsive 

@media (max-width: 767px) {
    .bewerber-section-second {
        .section-card {
            .section-phone {
                width: 15%; 
                img {
                    left: -80px;
                    height: 450px;
                    top: 150px;
                }
            }
            .section-text {
                margin-top: 20px;
                margin-left: -30px;
                .second-p {
                    font-size: 12px;
                }
            }
            .text-buttons {
                right: -10%;
            }

            .download-buttons {
                div {
                    width: 130px;
                   
                }
            }

            .third-p {
                font-size: 15px;
                margin-bottom: 20px;
            }
        }
    }
}

.bewerber-section-third{ 
    margin-top: 300px;
    .section-card{
        .section-text{
            margin-top: 70px;

            .first-p-B--bold{
                font-weight: bold;
            }
            .list-p{
                color: #e61f2a;
                font-weight: bold;
                margin-top: 60px;
            }

            ul{
                margin-top: 0px;
            }
        }
    }
    .section-buttons-B{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        button{
            margin: 0 30px;
        }
    }


}

@media (max-width: 767px) {
    .bewerber-section-third {
        .section-buttons-B {
            position: absolute;
            top: 450px;
            //right: 100px;
            button {
                margin: 0 5px;
                font-size: 10px !important;
                line-height: normal !important;
                width: 90px;
                padding-left: 0px !important;
                padding-right: 0px !important;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }
}

@media (max-width: 650px) {
    .bewerber-section-third {
        margin-top: 250px;
    }
}

.bewerber-section-fourth{ 
    height: 480px;
    align-items: flex-end;
    margin-top: 200px;
    .section-card{
        width: 90%;
        background-image: url(./../../assets/images/step-4-background.png);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        
        ;
        .section-home{
            width: 40%;
            height: 125%;
            position: relative;
            img{
                position: absolute;
                left: -198px;
                top: -165px;
                height: 766px;
            }
            > .shadow{
                position: absolute;
                width: 410px;
                height: 100%;
                box-shadow: inset 0px -77px 81px 0 #fff !important;
                bottom: -36px;
                left: 50px;
            }
            @media (max-width: 767px) {
                > .shadow {
                    display: none;
                }
            }
        }

      

        .section-text{
            margin-top: 70px;
            .first-p-B--red{
                font-size: 25px;
                color: #e61f2a;
                font-weight: bold;
                margin-bottom: 0;
            }
            h3{
                margin-bottom: 50px;
            }
            .first-p-B{
                margin-bottom: 60px;
            }
        }

          @media (max-width: 1024px) {
            .section-card {
                margin-right: 20px;
            }
            .section-home {
                width: 20%;
                img{
                    height: 400px;
                    top: 25px;
                    left: -100px;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        .section-card {
            margin-right: 20px;
            .section-home {
                width: 20%;
                img{
                    height: 400px;
                    top: 25px;
                    left: -100px;
                }
            }
        }
       
    }
    @media (max-width: 767px) {
      height: 650px;
            .section-card {
                .section-home {
                   img {
                    height: 250px !important;
                    top: 320px;
                    left: -30px;
                   }
                }
            }
        
    }


div:has(button){
    align-self: center;
    margin-left: 120px;
    margin-top: 65px;
}
@media (max-width: 767px) {
  div:has(button) {
    position: absolute;
    top: 95%;
   // left: -25%;
   left: 0;
   right: 0;
   margin: 0 auto;
   justify-content: center;
   display: flex;

  }
      
  }


}

.bewerber-section-fifth{
    height: 1000px;
    width: 100%;
    margin-top: 150px;
    padding-bottom: 20px;

    .section-content{
        height: 100%;
        width: 100%;
        background-image: url(../../assets/images/bewerber-fifth-background.png);
        background-position: center;
        background-size:cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;

        .content-title{
            width: 45%;
            height: 115px;
            border-radius: 25px;
            box-shadow: 7px 7px 15px 0 rgba(0, 0, 0, 0.16);
            background-color: #fff;
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p{
                margin-bottom: 5px;
                font-size: 16px;
                color: #000;
            }
            h4{
                font-size: 29px;
                font-weight: bold;
                color: #e71e2c;
            }
        }

        .content-steps{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 130px;

            >div{
                width: 300px;
                max-width: 350px;
                color: #000;
                font-size: 14px;
                margin: 0 30px;
                text-align: center;
                img{
                    width: 60%;
                    height: auto;
                }
                h5{
                    font-weight: bold;
                    margin-bottom: 20px;
                    line-height: 1.5;
                    margin-top: 20px;
                font-size: 14px;
                }
            }
        }

        @media (max-width: 1070px) {
            .content-steps{
                margin-top: 50px;
            }
        }

    }
}

@media (max-width: 767px) {
    .bewerber-section-fifth {
        margin-left: 10px;
    }
}

.bewerber-steps{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    z-index: 5;
    margin-left: 30px;

    svg{
        cursor: pointer;
        margin-bottom: 15px;
        width: 18px;
        height: auto;
    }
    .active-step{
        circle{
            fill: #e71e2c!important;
        }
    }
}


    
@media (max-width: 767px) {
    .bewerber-steps {
        margin-left: 5px;
        svg{
            width: 14px;
        }
    }
    .bewerber-section-fifth{
        margin-top: 100px !important;
       height: 1400px;
    }

    .lastButton {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 95% !important;
    }
}

@media (min-width: 1600px) {
    .bewerber-section {
        .section-card {
            height: 800px;
        }
    }
}
