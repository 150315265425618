.partner-job-card{
    margin: 0 auto !important;
    width: 320px !important;
    height: 410px;
    padding: 30px 30px 0 30px;
    border-radius: 4px;
    box-shadow: 10px 13px 20px 0 rgba(31, 29, 13, 0.2);
    background-color: #fff;
    position: relative;
    cursor: pointer;

  
    >button{
      position: absolute;
      bottom: -5%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: fit-content;
      padding: 5px 30px;
      font-size: 16px!important;
      height: 50px;
      
    }



    
  .card-type{
    height: 25px;
  border-radius: 4px;
  background-color: #e71e2c;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: center;
  color: #fff;
  width: fit-content;
  padding: 0 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -10px;
    display: flex;
    align-items: center;
  }
    .card-image{
      width: 100%;
      height: 120px; 
    position: relative;
      margin-bottom: 25px;

      .profile-image{
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 85px;
        border-radius: 50%;
        width: 112px;
        height: 113px; 
        border: solid 2px #e71e2c;
        background-color: #fff;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        border-radius: 50%;
        }
      }

      img:nth-of-type(1){
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    }
    .card-name{
      font-size: 18px;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
      color: #1f1d0d;
      margin-bottom: 0;
      hyphens: auto;
    }
    .card-gender{
        font-size: 13px;
        color: #1f1d0d;
        text-align: center;
        margin-bottom: 20px;
    }
    .card-establishment, .card-location{
      display: flex;
      align-items: center;
      margin-bottom: 13px;
  
      p{
          color: #29281e;
        margin: 0;
        margin-left: 18px;
        font-size: 14px;
      }
      svg{
        width: 25px;
      }
    }
  
    .card-establishment{
      p{
          font-weight: bold;
          hyphens: auto;
      }
    }
  }

@media (max-width: 1024px) {
  .partner-job-card{
    margin-left: 0px;
  }
}


@media (max-width: 768px) {
  .partner-job-card{
    margin-left: 20%;
  }
}

@media (max-width: 767px) {
  .partner-job-card{
    margin-left: 0%;
    width: 270px !important;
   // height: auto;
   
  }
}