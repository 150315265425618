.socialShare{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;

    span{
      opacity: 0.8;
      font-size: 16px;
      color: #1f1d0d;
      
    }
    .socialButtons{
        display: flex;
        flex-direction: column;
      button{
        margin: 5px 0;
      }
    }
  }