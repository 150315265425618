@import '../../assets/scss/deviceSize';

.jobsList {
  margin: 10px auto;
  padding: 20px 70px;
  display: grid;
  gap: 70px 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  @media (min-width: 1025px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 995px) {
    gap: 70px 50px
  }
  @media (max-width: 650px) {
    grid-template-columns: none;
  }
}

.allJobsButton{
  color: #e71e2c;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 40px;
  cursor: pointer;
}

.jobTitle{
  position: absolute;
  z-index: 5;
  right: -35px;
  height: 135%;
  top: 0;
  transform: scale(0.8, 0.8);
}

@media (min-width: 1500px) {
  .jobTitle{
    top: -300px;
  }
}