.partner-card{
  width: 300px;
  height: 360px;
  padding: 22px 22px 0 22px;
  border-radius: 4px;
  box-shadow: 10px 13px 20px 0 rgba(31, 29, 13, 0.2);
  background-color: #fff;
  position: relative;

  >button{
    position: absolute;
    bottom: -5%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 15px!important;
    line-height: 24px!important;
    width: 60%;
  }

  .card-image{
    width: 100%;
    height: 140px;
    border-radius: 4px;
    border: 1px solid lightgray;
    margin-bottom: 17px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .card-name{
    font-size: 18px;
    font-weight: bold;
    line-height: 0.82;
    text-align: center;
    color: #1f1d0d;
    margin-bottom: 20px;
    hyphens: auto;
  }
  .card-establishment, .card-location{
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    p{
        color: #29281e;
      margin: 0;
      margin-left: 18px;
      font-size: 15px;
    }
    svg{
      width: 25px;
    }
  }

  .card-establishment{
    p{
        font-weight: bold;
        width: calc(100% - 25px);
        hyphens: auto;
    }
  }
}