@import '../../assets/scss/deviceSize';

.price-card {
  width: 20%;
  min-width: 250px;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  position: relative;

  .price-title {
    position: absolute;
    top: -35px;
    width: 85%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    height: 72px;
    border-radius: 12.5px;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    p {
      margin: 0;
      font-size: 15px;
    }
    h5 {
      font-weight: bold;
      font-size: 22px;

      span {
        vertical-align: text-top;
        font-size: 16px;
      }
    }
  }
  .price {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #da0024;
    margin: 0;
    span {
      font-size: 13px;
      font-weight: normal;
    }
  }
  .collapse-button {
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jobs-number {
    font-size: 15px;
    color: #000;
    margin-top: 20px;
    text-align: center;
  }
  .jobs-number-main {
    color: #fff;
  }

  ul {
    padding: 0 20px;
    text-align: center;
    list-style: none;
    font-size: 13px;
    margin-top: 40px;
    li {
      padding: 5px 0;
    }
  }

  button {
    position: absolute;
    bottom: -20px;
  }
  .MuiButton-containedSecondary {
    padding-left: 30px;
    padding-right: 30px;
    border: none !important;
  }
}

.arbeitgeber {
  .arbeitgeber-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contact-person {
      width: 75%;
      height: 100%;

      .person-card {
        background-image: url(../images/step-background.png);
        position: relative;
        color: #000;
        font-size: 16px;
        overflow: hidden;
        p {
          padding: 0;
          margin: 0;
        }
        .person-picture {
          height: 100%;
          position: absolute;
          right: 0;
        }
        .button-video {
          position: absolute;
          bottom: -52px;
          margin: 0 auto;
        
          right: 0;
          border: none;
          outline: none;
          color: #fff;
          width: 323px;
          z-index: 1;
          height: 160px;
          border-radius: 50% / 100% 100% 0 0;
          background-color: #e71e2d;
          align-items: flex-start;
          justify-content: center;
          padding-bottom: 27px;
          cursor: pointer;

          svg {
            width: 50px;
            margin-right: 10px;
          }
        }
        .fur-arbeitgeber {
          margin-bottom: 10px;
        }
        h4 {
          color: #e71e2c;
          font-size: 34px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .ihr-partner {
          font-weight: bold;
          margin-bottom: 10px;
        }
        .name {
          font-weight: bold;
          padding: 0;
        }
        .title {
          font-size: 13px;
          margin-bottom: 16px;
        }
        .info {
          margin-bottom: 12px;
          svg {
            margin-right: 10px;
            width: 20px;
            height: auto;
          }
        }
      }
      .contact-person-buttons {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        button {
          margin: 0 20px;
        }
      }
    }
    .partners-image {
      img {
        width: 100%;
      }
    }
  }

  .pricing-note {
    font-size: 12px;
    color: #000;
  }
  .kunden {
    font-size: 14px;
    margin-top: 80px;
    margin-bottom: 8px;
  }
  .partners-title {
    font-size: 28px;
    font-weight: bold;
    color: #e71e2c;
    margin-bottom: 0;
    text-align: center;
  }
  .first-p {
    font-size: 17px;
    margin-bottom: 3px;
    margin-top: 170px;
  }
  .second-p {
    font-size: 28px;
    font-weight: bold;
    color: #e71e2c;
  }
  .arbeitgeber-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    > div {
      display: flex;
      justify-content: space-between;
      margin: 25px;
    }
  }

  .step-section {
    display: flex;
    //justify-content: space-between;
    width: 48% !important;
    max-width: 48% !important;
    img {
      width: auto !important;
      height: 130px !important;
    }
    svg {
      width: 40px;
      height: 40px;
      margin: 0 8px;
    }
    h5 {
      font-weight: bold;
      margin-bottom: 15px !important;
    }
    h5,
    p {
      font-size: 13px !important;
    }
  }

  .section-buttons {
    button {
      margin: 0 20px;
      top: 0px !important;
    }
  }

  .bewerber-section-fifth {
    position: relative;
    .section-shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      box-shadow: inset -58px -72px 59px 12px #fff;
    }
  }
}
@media only screen and (max-width: $newLarge) {
  .button-video {
    height: 152px;
    padding-bottom: 32px;
  }
  .person-picture {
    top: 0;
  }
  .person-card h4 {
    font-size: 27px !important;
    max-width: 200px;
  }
  .contact-person-buttons button {
    width: 140px;
    text-align: center !important;
  }
  .content-title {
    text-align: center;
  }
  .section-shadow {
    box-shadow: none !important;
  }
  .section-content {
    background-image: none !important;
    height: auto !important;
  }
  .step-section {
    flex-direction: column;
    text-align: center;
    img {
      object-fit: contain;
    }
    svg {
      margin: 10px !important;
    }
  }
  .section-buttons {
    display: flex;
    flex-direction: column;
  }
  .section-buttons button {
    margin: 10px 20px !important;
    width: 200px;
  }
  .first-p {
    margin-top: 350px !important;
  }
  #package {
    justify-content: center;
  }
  .price-card {
    margin: 50px 10px;
  }
}
@media only screen and (max-width: $newSmall) {
  .person-picture {
    display: none;
  }
  .person-card h4 {
    text-align: center;
  }
  .button-video {
    height: 130px !important;
    left: 11px !important;
    width: 270px !important;
  }
  .bewerber-section-fifth {
    margin-top: 300px;
  }
  .content-title {
    width: 70% !important;
  }
  .arbeitgeber-steps > div {
    margin: 5px !important;
  }
  .price-card:first-child {
    margin: 0px 10px 100px 10px;
  }
  .contact-person-buttons {
    flex-direction: column;
    align-items: center;
    button {
      width: 230px;
      margin: 10px 20px !important;
    }
  }
}

@media (max-width: 1200px) {
  .arbeitgeber{
    .arbeitgeber-container {
      .contact-person {
        margin-bottom: 100px;
      }
    }
  }

}

@media (max-width: 767px) {
  .arbeitgeber {
    overflow: hidden;
    .step-section {
      width: 38% !important;
    }
    .arbeitgeber-steps {
      >div {
        justify-content: space-evenly;
      }
    }
  }
}

@media (max-width: 600px) {
  .section-content {
    //margin-top: 400px !important;
  }

  .first-p {
    margin-top: 550px !important;
  }

}







