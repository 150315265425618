.blogs{
    .search-bar{
        width: 85%;
        display: flex;
        border-radius: 10px;
        box-shadow: 10px 13px 26px 0 rgba(0, 0, 0, 0.16);
        background-color: #e6e6e6;
        padding: 25px 150px 25px 20px;
        max-width: 1150px;

        .search-input {
            flex: 3;
            margin-right: 15px;
        }

        .search-text {
            font-size: 14px;
            margin-bottom: 5px;
            opacity: 0.8;
        }

        .sort-input {
            flex: 3;
            margin-left: 15px;
            display: flex;
            align-items: center;

            .sort-icon{
                width: 15px;
                height: auto;
            }
        }

        .MuiInputBase-root {
            height: 40px;
        }

        .MuiOutlinedInput-root {
            border-radius: 10px;
        }

        .MuiOutlinedInput-input {
            padding-left: 15px;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
            border: none;
        }

        .MuiOutlinedInput-inputAdornedEnd {
            height: 45px;
            box-sizing: border-box;
        }

        .sort-text {
            flex: 1;
        }

        .sort-box {
            flex: 4;
        }
        .blog-search-button{
            top: -20px;
     
        }
    }
}

@media only screen and (max-width: 798px) {
    .blogs{
        .search-bar{
            flex-direction: column;
            .search-input {
              
                margin-right: 0;
                margin-bottom: 20px;
              }
          
              .sort-input {
                margin-left: 0px !important;
                flex-direction: column;
                align-items: flex-start;
          
                .sort-text {
                  font-size: 14px;
                  margin-bottom: 5px;
                  opacity: 0.8;
                }
              }
              .blog-search-button{
                top: 12px !important;
                //right: -10%;
                right: 0;
         
            }
        }
        
    }

  
  }

  @media (max-width: 767px) {
    .blogs{
        .search-bar {
            align-items: center;
            padding: 25px 12px;
            .search-input {
                width: 100%;
            }

            .sort-input {
                width: 100%;
            }
            .blog-search-button{
                top: 90% !important;
                //right: -10%;
                right: 0;
         
            }
        }
    }
  }