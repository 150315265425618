@import '../../assets/scss/deviceSize';

.linksWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 50px 0 20px 0;
}

.linksWrapper p {
  font: 16px 'Montserrat';
  margin: 10px 30px;
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 8px;
}

.activeLink {
  font-weight: 600;
  color: #e83741;
  border-bottom: 2px solid #e83741;
}

.mapContainer {
  height: 400px;
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

//company main

.companyDesc {
  width: 100%;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;

  img {
    width: 45%;
    height: auto;
  }
  p {
    //width: 52%;
    color: #707070;
    font-size: 16px;
  }
}

.socialMedia {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto 10px;

  img {
    margin: 0 5px;
    cursor: pointer;
  }

  a {
    cursor: pointer;
  }
}
//media queries

// @media (max-width: 1128px) {
//   .linksWrapper {
//     margin: 50px 0 20px 0px;
//   }
// }

@media only screen and (max-width: $newSmall) {
  .companyInfoContainer {
    flex-direction: column;
  }

  .companyInfo {
    width: 100%;
    padding: 10px 5px 20px;
    align-items: center;
    text-align: center;
  }

  .companyDesc {
    max-width: 100%;
    border: none;
    border-top: 1px solid #e83741;
    text-align: center;
    padding: 20px 5px;
  }

  .mapContainer {
    width: 100%;
    button {
      top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
}

@media (max-width: 767px) {
  .companyInfo {
    text-align: left;
  }
}
