@import 'config';

.footer {
  text-align: center;
  width: 100% !important;
  display: flex;
  row-gap: 10px;
  flex-direction: row;
  background-color: $footer-color;
  color: white;
  font-size: 15px;
  height: 380px;
  font-weight: bold;
  overflow-x: hidden;

  .footer-links {
    margin-top: 70px;
  }

  .follow-us {
    width: 150px;
  }

  .footerChild.mobile-footer {
    flex-direction: column;
    align-items: center;
  }

  .footerChild.mobile-footer .group {
    margin-bottom: 30px;
  }

  .follow-us-child img:hover {
    opacity: 0.8;
  }

  .footerChild {
    display: flex;
    justify-content: space-around;
    position: relative;

    .arrowup {
      height: 70px;
      width: 70px;
      top: -35px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 70px;
      cursor: pointer;
      background-color: white;
      border: 1px solid $primary-color;
      &:hover {
        background-color: rgb(247, 247, 247);
      }
    }

    .group {
      width: 150px;
      flex-direction: column;
     
      margin-right: 60px;
      align-items: center;
      img{
        width: 55px;
        height: 55px;
      }
      #title {
        border-bottom: 1px solid white;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 10%;
        }
      }
   
    }

   

    #social-media {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      #icons {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
        width: 100%;
        a {
          margin: 0 10px;
        }

        & img {
          height: 30px;
        }

        & a:last-child img {
          height: 35px;
        }
      }
    }
    #social-media-title {
      border-bottom: 2px solid white;
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 80px;
    }
  }

  ul {
    li {
      list-style: none !important;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .footerChild {
    .group {
      margin-bottom: 5%;
      display: flex;
      text-align: center;
    }
    a {
      margin-bottom: 2%;
    }
  }

  .footer {
    font-size: 16px;
  }
}
