@import '../../../assets/scss/deviceSize';

.hobbiesForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  //min-width: 450px;
}

@media (max-width: 992px) {
  .hobbiesForm {
    flex-direction: column;
  align-items: normal;
  min-width: 0px;
  width: 100% !important;
  }
}
