.activeLink{
    border-bottom: 4px solid #E83741!important;
}
.create-profile-button > button{
    box-shadow: none !important;

}

.bolded-p {
    font-size: 25px;
    font-weight: 600;
    color: #575757;
}


.simple-p {
    font-size: 16px;
    font-weight: 400;
    color:  #575757;
    margin-bottom: 30px;
}

.email {
    font-size: 16px;
    font-weight: 400;
    color: #929292;
}

.pass {
    font-size: 12px;
    font-weight: 400;
    color: #575757;
}

.register-b {
    width: 100%;
    background-color: #E83741;
    border-radius: 12px !important;
    margin-bottom: 40px;
    margin-top: 30px;
}

.span-pass {
    color:   #E83741;
    cursor: pointer;
    margin-left: 5px;
}

.last-p {
    font-size: 16px;
    font-weight: 500;
    color: #575757;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-e {
    border: none;
  border-bottom: 1px solid #CFCFCF;
  outline: none;
  margin-bottom: 20px;
  width: 100%;

}

.bottom-1 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
