.strasseCard {
  height: 29.125rem;
  max-height: 380px;
  min-width: 260px;
  width: 45%;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.625rem rgba(205, 205, 205, 0.25);
  border-radius: 0.625rem;
  padding: 35px;
  margin: 15px;
  flex-grow: 1;
}
.strasseCard__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
  padding: 0px !important;
}

.fullWidth {
  width: 100%;
}

.marginFirstCard {
  margin: 0 0 15px 0;
  height: fit-content;
  max-height: none;
}
.marginSecondCard {
  margin: 30px 0 0 0;
  max-height: 220px;
}

.hobbysCard {
  height: auto;
  max-height: none;
}

.strasseCard__header p {
  font-size: 1.125rem;
  color: #b62931;
  font-weight: 600;
  margin-bottom: 0;
}
.strasseCard__header svg {
  cursor: pointer;
}

.strasseCard
  .MuiDataGrid-columnHeader:nth-of-type(2)
  .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: left;
  padding: 0px !important;
}

.strasseCard
  .MuiDataGrid-columnHeader:nth-of-type(1)
  .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: left;
  padding: 0px !important;
}
.strasseCard .MuiDataGrid-cell:nth-of-type(2) {
  text-align: center;
  font-weight: 600;
  color: #767676;
}
.strasseCard .MuiDataGrid-cell:nth-of-type(1) {
  color: #731313;
  font-weight: 600;
}

.brancheCard {
  background-color: #fff;
  box-shadow: 0 0.25rem 0.625rem rgba(205, 205, 205, 0.25);
  min-width: 260px;
  border-radius: 0.625rem;
  padding: 35px;
  flex: 1;
  margin: 0.5rem 0.8rem;
}

.brancheCard__content {
  display: flex;
  flex-wrap: wrap;
}
.brancheCard__card {
  border: 2px solid #e83741;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: #3a3a3a;
  font-size: 0.95rem;
  padding: 0.4rem 0.5rem;
  margin: 0.5rem 0.7rem;
}
.brancheCard__card svg {
  cursor: pointer;
}
.brancheCard__card span {
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
}

.jobCard {
  padding: 2rem;
}
.jobCard table {
  width: 100%;
}

.jobCard table td {
  padding: 0.2rem;
  width: 50%;
  color: #494949;
}
.jobCard table td:nth-of-type(1) {
  font-weight: 600;
}

@media (max-width: 576px) {
  .strasseCard,
  .brancheCard {
    padding: 25px 20px;
    margin: 10px;
  }
}
