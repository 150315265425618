.profile-settings__image {
  width: 193px;
  height: 193px;
  border-radius: 50%;
  position: relative;
}
.profile-settings__image:hover svg {
  opacity: 1;
  cursor: pointer;
}
.profile-settings__image > svg {
  position: absolute;
  opacity: 0;
  width: 100%;
}
.profile-settings__image > img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  width: 193px;
  height: 193px;
  object-fit: cover;
  border-radius: 50%;
}
.loadingImage {
  height: 100%;
  width: 100%;
  background-color: #8080800a;
  border-radius: 50%;
}
.loadingImage > div {
  height: 100%;
  width: 100%;
  margin: 0 !important;
}
.loadingImage > div > div {
  margin: 0 !important;
}
