.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blured-container {
  filter: blur(15px);
  margin: 90px auto;
  padding: 20px;
  display: grid;
  gap: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  width: 100%;

  @media (max-width: 650px) {
    grid-template-columns: none;
  }
}

.box {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 12px;
  }
  
  p:nth-of-type(1){
    font-size: 23px;
    color: #e61f2a;
    font-weight: bold;
  }

  p:nth-of-type(2){
    font-size: 15px;
    color: #000;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .box-buttons {
    display: flex;
    margin-top: 20px;

    button{
      width: 200px;
    }
  }

  .box-buttons a:nth-of-type(1) > button {
    margin-right: 30px;
  }
}
