@import 'config';

.searchbar{
    width: 100%;
    margin: 2% 0;
}

.title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h1{
        color: $primary-color;
    }
    small{
        margin-right: 5%;
        color: $primary-text-color;
        font-size: 14px;
    }
}

.searchbars{
    display: flex;
    align-items: flex-start;
    margin: 2% 0;

    input{
        padding: 1%;
        border-radius: 100px;
        border: none;
        outline: none;
    }
    #searchbarOne{
        margin: 0;
        width: 423px;   
        margin-right: 2%;
    }
    #searchbarTwo{
        margin: 0;
        width: 313px;   
        margin-right: 5%;

    }
    button{
        width: 251px;
        height: 41px;
        background-color: $primary-color-opp80;
        color: white;
        border: none;
        outline: none;
        border-radius: 100px;
    }
}

