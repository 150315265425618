@import '../../assets/scss/deviceSize';
.modalHeader {
  position: relative;
  height: auto;
  padding: 30px;
  width: 100%;
  box-shadow: 5px 0px 10px rgba(225, 225, 225, 0.25);
  border-radius: 30px 30px 10px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
.headerLine {
  width: 1px;
  height: 100%;
  border-left: 1px #c7c7c7 dashed;
  //   margin: 20px 0;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
}

.jobInfo h1 {
  font: 600 22px Montserrat;
}

.headerRow,
.headerItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font: 500 14px 'Montserrat';
}

.headerRow {
  flex-wrap: wrap;
}

.headerItem {
  margin: 10px 5px 0 0;
}

.headerBullet {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgb(60, 60, 60);
  margin: 0 15px;
}

.headerItem p {
  margin: 0;
}

//applicant info card

.imageContainer,
.imageContainer img {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 10px 0;
  border-radius: 10px;
  object-fit: cover;
}

.contactItem {
  display: flex;
  margin-bottom: 20px;
}

.contactItem img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.contactItem p {
  margin: 0;
}

.firstTwoCards {
  //   width: 100%;
  //   height: 100%;
  //   max-height: fit-content;
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.applicantCard {
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: $newSmall) {
  .modalHeader {
    padding: 15px 10px;
  }

  .headerLine {
    border: none;
  }
}
