$fa-font-path: "~font-awesome/fonts";

$background-color:#FAFAFA;
$primary-text-color: #B1B1B1;   
$navbar-tabs : #C6C6C6;
$primary-color:#E83741;
$footer-color:#E83741;
$primary-color-opp80:rgba(227,6,19,0.8);
$heading-font-size:2.3em;
$font-stack:'Montserrat';
$button-color:#E83741;

ol {
    width: 40%;
    li{
      display: inline;
      font-size: 16px;
      margin: 0 8%;
      white-space: nowrap;
      color: $navbar-tabs;
    }
  }