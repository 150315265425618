@import url('https://fonts.cdnfonts.com/css/montserrat');
@font-face {
  font-family: 'Montserrat';
  src: url(https://fonts.cdnfonts.com/css/montserrat);
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e3e3e !important;
  background: #fff;
  font-size: 16px;
  /* padding-right: 8px !important; */
}

code {
  font-family: 'Montserrat';
}

/* Override */
.MuiInputBase-root,
.MuiMenuItem-root,
.MuiFormLabel-root {
  font-family: 'Montserrat'!important;
}
.react-photo-gallery--gallery img {
  height: 150px;
  width: 30%;
  object-fit: cover;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
}

.MuiSelect-root.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e83741 !important;
  border-width: 1px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #e83741 !important;
  font-size: 1.2rem !important;
}
.MuiFormLabel-root {
  font-size: 1.1rem !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #ddd !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid #e83741 !important;
}
.main-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
/* .MuiChip-deleteIconOutlinedColorSecondary {
  color: #e83741 !important;
} */
.MuiRadio-colorSecondary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #e83741 !important;
}
.MuiChip-colorSecondary {
  background-color: #e83741 !important;
}
.MuiChip-outlined {
  background-color: transparent !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiListItem-root {
  min-height: 36px !important;
}

.MuiOutlinedInput-root {
  border-radius: 0;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #ddd !important;
}

@media (min-width: 768px) {
  .main-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .main-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .main-container {
    width: 1200px;
  }
}

@media (min-width: 1600px) {
  .main-container {
    width: 1400px;
  }
}

#modal {
  width: 90vw;
  max-width: 700px;
  min-height: 500px;
}

#modal .MuiChip-root {
  height: auto;
  border-radius: 30px;
}

#modal .MuiChip-label {
  white-space: initial;
}

#modal .MuiSelect-selectMenu {
  white-space: break-spaces;
}

#sidebar.mobile {
  width: 70px;
}

.loadingCover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #21252936;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiTooltip-popper {
  z-index: 1300 !important;
}

/* hide arrows for input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.rdw-image-wrapper {
  margin: 0 !important;
}

.rdw-image-wrapper div {
  margin: 0;
  padding: 15px 10px;
}

.rdw-image-wrapper > div:hover {
  background-color: #f4f4f4;
  box-shadow: none;
}
.rdw-block-wrapper {
  margin: 0 !important;
}

/*slider*/
.MuiSlider-valueLabelOpen{
  min-width: fit-content;
}
