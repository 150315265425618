.quick-apply-modal{

    .quick-apply-modal__header{
        font-weight: 600;
        font-size: 23px;
        color: #575757;
        margin-bottom: 50px;
    }

    .quick-apply-modal__info{
         > label {
            width: 100%;
            font-size: 15px;
         }

         >label:nth-of-type(1) {
            font-weight: 400!important;
            color: #929292;
         }

         >label:nth-of-type(2) {
            font-weight: 600!important;
            color: #494949;
         }
    }
}