.innerDiv {
  padding: 40px 20px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.innerDiv p {
  margin-bottom: 20px;
}
