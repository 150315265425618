@import 'config';
@import 'searchBar';
@import 'footer';
@import 'imprint';
@import 'partner';

// @import "~font-awesome/scss/font-awesome";
//Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.cdnfonts.com/css/montserrat');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat'!important;
}

body {
  background-color: $background-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

h1 {
  font-size: $heading-font-size;
}

body::-webkit-scrollbar, .dashboard-container::-webkit-scrollbar {
  width: 8px;
}

input {
  cursor: pointer;
}

body::-webkit-scrollbar-track, .dashboard-container::-webkit-scrollbar-track  {
  background: #fafafa;
}

body::-webkit-scrollbar-thumb, .dashboard-container::-webkit-scrollbar-thumb {
  background: #c9333c;
  border-radius: 8px;
}

.yellow {
  background-color: #c9333c;
  color: #fafafa;
}

.fc-p {
  color: #e83741 !important;
}

.box {
  text-decoration: none;
  margin: 0 2% 5% 0;
  padding: 1% 6%;
  border-radius: 25px;
  cursor: pointer;
}
.inactive {
  background-color: rgba(222, 222, 222, 0.25);
  color: rgba(193, 193, 193, 1) !important;
}

.active {
  color: #fff !important;
}

.isClicked {
  border: 1px solid #e83741;
}
#particles canvas {
  position: absolute;
  width: 100%;
  height: 550px !important;
  margin-top: 70px;
  z-index: -1;
  display: none;
}
.pac-container.pac-logo {
  z-index: 1301;
}

.heading {
  font-size: 30px;
  line-height: 45px;
  font-weight: 400;
  color: #e83741;
}

label {
  font-size: 15px;
}

h3 {
  color: #3e3e3e;
}

.MuiStepConnector-vertical {
  margin-left: 22px !important;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed,
.MuiChip-outlinedSecondary {
  color: #e83741 !important;
}
.MuiTypography-caption {
  font-size: 0.95rem !important;
}
.MuiButton-containedPrimary,
.MuiButton-containedSecondary {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
  text-align: left !important;
}

.MuiButton-root {
  text-transform: initial !important;
  border-radius: 10px !important;
}

.MuiButton-containedPrimary {
  background-color: $button-color !important;
  transition: none;
  border: 1px solid transparent !important;
  border-radius: 4px!important;
  box-shadow: 10px 10px 11px 0 rgba(0, 0, 0, 0.16)!important;
  // padding: 13px 32px!important;
  font-family: Montserrat;
  // font-size: 19px!important;
  padding-left: 30px;
  padding-right: 30px;
  width: fit-content;
}

.MuiButton-containedPrimary:hover {
  background-color: white !important;
  color: #E83741 !important;
  border: 1px solid #e83741 !important;
}

.MuiButton-containedSecondary {
  border: 1px solid #e83741 !important;
  color: #e83741 !important;
  background-color: white !important;
  border-radius: 4px!important;
}

.MuiButton-containedSecondary:hover {
  border: 1px solid transparent !important;
  color: white !important;
  background-color: #e83741 !important;
}
.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.MuiStepLabel-label {
  font-size: 22px !important;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

img.card-image-big {
  height: 110%;
}

img.card-image-small {
  height: 80%;
}

.MuiStepLabel-label.MuiStepLabel-active {
  font-weight: bold !important;
}

.makeStyles-stepContent-83 {
  text-align: left !important;
}

.makeStyles-stepFooter-2 {
  justify-content: flex-end !important;
  button {
    margin: 2% 2%;
  }
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #e83741 inset !important;
}

.MuiChip-root {
  margin: 1% !important;
  cursor: pointer !important;
  font-size: 13px !important;
}

.MuiChip-colorPrimary {
  background-color: #fff !important;
  color: #e83741 !important;
}

.kliknuto {
  background-color: #fff !important;
}
.MuiFormControl-marginNormal {
  width: 100%;
}

body {
  background-color: #fff;
  overflow-x: hidden;
}

.Bilal {
  position: absolute;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  left: 46%;
  font-weight: bold;
  top: 58%;
  //margin-left: -20px;
}

.body {
  position: absolute;
  top: 50%;
  //margin-left: -50px;
  left: 50%;
  animation: speeder 0.4s linear infinite;

  > span {
    height: 5px;
    width: 35px;
    background: #e83741;
    position: absolute;
    top: -19px;
    left: 60px;
    border-radius: 2px 10px 1px 0;
  }
}

.base {
  span {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 100px solid #000;
    border-bottom: 6px solid transparent;

    &:before {
      content: '';
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background: #000;
      position: absolute;
      right: -110px;
      top: -16px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 0 solid transparent;
      border-right: 55px solid #000;
      border-bottom: 16px solid transparent;
      top: -16px;
      right: -98px;
    }
  }
}

.face {
  position: absolute;
  height: 12px;
  width: 20px;
  background: #000;
  border-radius: 20px 20px 0 0;
  transform: rotate(-40deg);
  right: -125px;
  top: -15px;

  &:after {
    content: '';
    height: 12px;
    width: 12px;
    background: rgb(24, 23, 23);
    right: 4px;
    top: 7px;
    position: absolute;
    transform: rotate(40deg);
    transform-origin: 50% 50%;
    border-radius: 0 0 0 2px;
  }
}

.body > span > span:nth-child(1),
.body > span > span:nth-child(2),
.body > span > span:nth-child(3),
.body > span > span:nth-child(4) {
  width: 30px;
  height: 1px;
  background: #000;
  position: absolute;
  animation: fazer1 0.2s linear infinite;
}

label {
  font-weight: normal !important;
}
.block {
  display: block;
}
.body > span > span:nth-child(2) {
  top: 3px;
  animation: fazer2 0.4s linear infinite;
}

.body > span > span:nth-child(3) {
  top: 1px;
  animation: fazer3 0.4s linear infinite;
  animation-delay: -1s;
}

.body > span > span:nth-child(4) {
  top: 4px;
  animation: fazer4 1s linear infinite;
  animation-delay: -1s;
}

.op-8 {
  opacity: 0.8 !important;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.mb-08 {
  margin-bottom: 0.8rem;
}
@keyframes fazer1 {
  0% {
    left: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}

@keyframes fazer2 {
  0% {
    left: 0;
  }
  100% {
    left: -100px;
    opacity: 0;
  }
}

@keyframes fazer3 {
  0% {
    left: 0;
  }
  100% {
    left: -50px;
    opacity: 0;
  }
}

@keyframes fazer4 {
  0% {
    left: 0;
  }
  100% {
    left: -150px;
    opacity: 0;
  }
}

@keyframes speeder {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.longfazers {
  position: absolute;
  width: 100%;
  // height: 100%;

  span {
    position: absolute;
    height: 2px;
    width: 20%;
    background: #e83741;

    &:nth-child(1) {
      top: 20%;
      animation: lf 0.6s linear infinite;
      animation-delay: -5s;
    }

    &:nth-child(2) {
      top: 40%;
      animation: lf2 0.8s linear infinite;
      animation-delay: -1s;
    }

    &:nth-child(3) {
      top: 60%;
      animation: lf3 0.6s linear infinite;
    }

    &:nth-child(4) {
      top: 80%;
      animation: lf4 0.5s linear infinite;
      animation-delay: -3s;
    }
  }
}

@keyframes lf {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}
@keyframes lf2 {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}
@keyframes lf3 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}
@keyframes lf4 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

.applications__buttons{
  display: flex;
  flex-direction: column;
  align-items: center;

  button:nth-child(1){
    margin-right: 0!important;
    margin-bottom: 10px;
    min-height: 35px;
    height: fit-content;
  }
}

.input-datetime{
  height: 40px;
  width: 100%;
  position: relative;
  outline: none;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #dddddd;
  padding-left: 8px;
  &:focus {
    border-color: #e30613;
  }
}

.datetime-create-profile{
  color: #a2a2a2;
  font-size: 13px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #d6d6d6;
  padding-left: 20px;
  color: #7c7c7c;
  &:focus {
    border-color: #d6d6d6;
  }
}

.inputEye {
  cursor: pointer;
  color: #7c7c7c;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .datetime-create-profile {
    padding-left: 5px;
  }
}

.job-preview{
  .MuiButton-contained.Mui-disabled{
    background-color: #e69da1!important;
  }
 
}
.MuiMenuItem-root{
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
}
.MuiTypography-root{
  font-size: 14px;
}
.MuiCheckbox-root{
  padding: 6px;
}

/*
Slider
*/

.slick-slider{
  width: 100%;
  margin-top: 60px;

  > svg{
    width: 40px;
    height: auto;
    z-index: 2;
  }
  .slick-prev {
    left: 5%;
}
.slick-next {
  right: 6%;
}
  .slick-list{
    padding-top: 30px!important;
    padding-bottom: 45px!important;
    padding-left: 30px !important;
    //margin-right: 100px;
   // margin-left: 100px;
   width:90%;
   margin: auto;


    .slick-center{
      .partner-job-card{
        transform: scale(1.06);
      }
    }
  }
}

.maxWidth {
  max-width: 1600px;
  margin: auto;
}

.pagCenter {
  display: flex;
  justify-content: center;
}

.logoImg {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  cursor: pointer;
}

.partner1Img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  position: relative;
}

.partner1Buttons {
  position: absolute;
  bottom: 30px;
  flex-wrap: wrap;
 width: 100%;

  display: flex;
  justify-content: center;
  
}

@media (max-width: 9200px) {
  .partner1Button {
    margin-bottom: 12px !important;
  }
}

@media (max-width: 530px) {
  .partner1Button {
    margin-bottom: 6px !important;
    width: 230px;
  }
}

.partner1Button {
  margin: 0 10px;
  width: 230px;
}

.partner1Wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.reg-new {
  height: 700px;
  width: 600px;
  border: 1px solid;
  border-radius: 30px;
  margin: 0 auto;
}

@media (max-width: 600px) {
 .reg-new {
  width: 100%;
  height: auto;
 }
}

.log-new {
  height: 550px;
  width: 600px;
  border: 1px solid;
  border-radius: 30px;
  margin: 0 auto;
}

@media (max-width: 600px) {
 .log-new {
  width: 100%;
  height: auto;
 }
}
@media (max-width: 767px) {
  .singleButton {
    top: auto;
    right: auto;
    left: auto;
  }
}
.socShare {
  display: flex;
  position: absolute;
  justify-content: flex-start;
  top: 180px;
  right: 70px;
  align-items: center;
}
@media (max-width: 600px) {
  .socShare {
    top: 200px;
    right: 60px;
  }
}


.impressumHeader {
  font-size: 17px;
  color: #E71E2C;
  margin-bottom: 14px;
  margin-top: 14px;
}

.single-line-input {
  border: none; 
  border-bottom: 1px solid #CFCFCF; 
  outline: none; 
  padding: 5px; 
}


.single-line-input:focus {
  border-bottom: 2px solid #E83741;
}

.linkText {
  font-size: 12px;
  font-weight: 400;
  color: #929292;
}

